<template>
  <div class="page-center text-left">
    <vca-card class="form-center">
      <vca-field>
        <form>
          <h3>{{ $t("profile.display_name") }}</h3>
          <vca-row>
            <vca-input
              ref="display_name"
              :errorMsg="$t('sign.reset_information.display_name.error')"
              :placeholder="
                $t('sign.reset_information.display_name.placeholder')
              "
              v-model.trim="current.display_name"
              :rules="$v.current.display_name"
            >
            </vca-input>

            <div class="inline-infobox">
              <vca-info-box>{{
                $t("sign.reset_information.display_name.description")
              }}</vca-info-box>
            </div>
          </vca-row>
          <vca-field-row>
            <div class="vca-input first vca-fake-input">
              <div class="vca-fake-label">
                <h3>{{ $t("profile.first_name") }}</h3>
              </div>
            </div>
            <div class="vca-input last vca-fake-input">
              <div class="vca-fake-label">
                <h3>{{ $t("profile.last_name") }}</h3>
              </div>
            </div>
          </vca-field-row>
          <vca-field-row>
            <vca-input
              ref="first_name"
              label="adsf"
              first
              :errorMsg="$t('sign.reset_information.first_name.error')"
              :placeholder="$t('sign.reset_information.first_name.placeholder')"
              v-model.trim="current.first_name"
              :rules="$v.current.first_name"
            >
            </vca-input>
            <vca-input
              ref="last_name"
              last
              :errorMsg="$t('sign.reset_information.last_name.error')"
              :placeholder="$t('sign.reset_information.last_name.placeholder')"
              v-model.trim="current.last_name"
              :rules="$v.current.last_name"
            >
            </vca-input>
          </vca-field-row>
          <button class="vca-button" @click.prevent="validate">
            {{ $t("sign.reset_information.title") }}
          </button>
        </form>
      </vca-field>
    </vca-card>
  </div>
</template>
<script>
export default {
  name: "UserEditForm",
  data() {
    return {
      password_check: "",
    };
  },
  computed: {
    current: {
      get() {
        return this.$store.state.user.current;
      },
      set(value) {
        this.$store.commit("user/current", value);
      },
    },
  },
  validations() {
    return this.$store.getters["user/validation"];
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.display_name.validate();
        this.$refs.first_name.validate();
        this.$refs.last_name.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      this.$store.dispatch({ type: "user/submit" }).then(() => {
        this.$emit("success");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vca-fake-input {
  margin: 0;
}
.vca-fake-label {
  width: 95%;
  text-align: left !important;
}
</style>
