<template>
  <div class="text-left">
    <vca-card>
      <UserEditForm @success="success" />
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserEditForm from "@/components/UserEditForm";
export default {
  name: "UserEdit",
  components: {
    UserEditForm,
  },
  computed: {
    ...mapGetters({
      callback: "callback",
    }),
  },
  methods: {
    success() {
      var that = this;
      setTimeout(function () {
        if (that.callback) {
          window.location.href = window.atob(that.callback);
        } else {
          that.$router.push({ path: "/login" });
        }
      }, 3000);
    },
  },
};
</script>
